import type {DefaultValidationConfig, ValidationConfig} from "./validations";
import {Validation} from "./validations";
import type {InputType} from "../commons";
import type {InputElement} from "../components/inputElement";

export class Required extends Validation {

    private constructor(messageText: string, onlyWarning: boolean) {
        super(messageText, onlyWarning);
    }

    public static from(config: ValidationConfig<DefaultValidationConfig>): Required {
        return new Required(config.message, config.onlyWarning);
    }

    protected valid(value: unknown): boolean {
        return !!value;
    }

    public accept<T extends InputType>(inputElement: InputElement<T>, value?: T): void {
        inputElement.setRequired(true);
        if (!this.isValid(value)) {
            inputElement.addMessage(this.errorText, false);
        }
        if (this.isWarning(value)) {
            inputElement.addMessage(this.errorText, true);
        }
    }
}