import {autoRegister, resolve} from "../../container";
import {FormSessionId} from "./formSessionId";

const UUID_LENGTH = 36;

export class UploadResponse {
    public constructor(public uploadKey: string, public status: number) {
    }
}

export class UploadResponseError extends Error {
    public constructor(public status: number) {
        super();
    }
}

export class UploadDeleteRequest {
    public constructor(
        public fileKey: string,
        public configId: string,
        public formId: string,
        public lang: string
    ) {
    }
}

export class UploadRequest {
    public constructor(
        public file: File,
        public configId: string,
        public formId: string,
        public onProgress: (progress: number) => void,
        public lang: string
    ) {
    }
}

@autoRegister()
export class UploadService {

    private UPLOAD_ENDPOINT: string = "/enbw-eop/eop-app-form/rest/stable/attachments/upload";
    private DELETE_ENDPOINT: string = "/enbw-eop/eop-app-form/rest/stable/attachments/delete";

    public constructor(private sessionIdGenerator: FormSessionId = resolve(FormSessionId)) {
    }

    public async upload(request: UploadRequest): Promise<UploadResponse> {
        return new Promise((res, rej) => {
            const httpRequest = new XMLHttpRequest();
            const data = new FormData();
            data.append("file", request.file);

            httpRequest.onload = () => {
                const success = httpRequest.status === 200
                    && typeof httpRequest.response === "string"
                    && httpRequest.response.length === UUID_LENGTH;

                if (success) {
                    res(new UploadResponse(httpRequest.response, httpRequest.status));
                } else {
                    rej(new UploadResponseError(httpRequest.status));
                }
            };
            httpRequest.upload.onprogress = (event: ProgressEvent) => request.onProgress(100 / event.total * event.loaded);
            httpRequest.open("POST", `${this.UPLOAD_ENDPOINT}/${request.configId}/${request.lang}/${(this.getSessionId(request.formId))}`);
            httpRequest.send(data);
        });
    }

    public async delete(request: UploadDeleteRequest): Promise<void> {
        const response = await fetch(`${this.DELETE_ENDPOINT}/${request.configId}/${request.lang}/${this.getSessionId(request.formId)}/${request.fileKey}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json;charset=UTF-8"
                },
                body: ""
            });

        if (!response.ok) {
            throw new Error(`Response failed with status ${response.status}: ${response.statusText}`);
        }
    }

    private getSessionId(formId: string): string {
        return this.sessionIdGenerator.get(formId);
    }
}