import {autoRegister} from "../../../container";
import {promiseFromJsonResponse} from "../../../common/utils/promises";

export type ActionResponse = {
    success: boolean;
};

@autoRegister()
export class ActionService {
    private readonly missionOneConfirmUrl: string;
    private readonly missionOneUnsubscribeUrl: string;
    private readonly cyberSecurityConfirmUrl: string;
    private readonly cyberSecurityUnsubscribeUrl: string;

    public constructor() {
        this.missionOneConfirmUrl = "/enbw-eop/eop-app-form/rest/stable/action/missionone/confirm/";
        this.missionOneUnsubscribeUrl = "/enbw-eop/eop-app-form/rest/stable/action/missionone/unsubscribe/";
        this.cyberSecurityConfirmUrl = "/enbw-eop/eop-app-form/rest/stable/action/cybersecurity/confirm/";
        this.cyberSecurityUnsubscribeUrl = "/enbw-eop/eop-app-form/rest/stable/action/cybersecurity/unsubscribe/";
    }

    public async confirmMissionOneSubscriber(request: MissionOneMidRequest): Promise<ActionResponse> {
        const response = await fetch(`${this.missionOneConfirmUrl}${request.getMid()}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=UTF-8"
            },
            body: JSON.stringify({mid: request.getMid()})
        });
        return promiseFromJsonResponse(response);
    }

    public async deleteMissionOneSubscriber(request: MissionOneMidRequest): Promise<ActionResponse> {
        const response = await fetch(`${this.missionOneUnsubscribeUrl}${request.getMid()}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=UTF-8"
            },
            body: JSON.stringify({mid: request.getMid()})
        });
        return promiseFromJsonResponse(response);
    }

    public async confirmCyberSecuritySubscriber(request: CyberSecurityOptInIDRequest): Promise<ActionResponse> {
        const response = await fetch(`${this.cyberSecurityConfirmUrl}${request.getOptInId()}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=UTF-8"
            },
            body: JSON.stringify({optInId: request.getOptInId()})
        });
        return promiseFromJsonResponse(response);
    }

    public async unsubscribeCyberSecuritySubscriber(request: CyberSecurityOptInIDRequest): Promise<ActionResponse> {
        const response = await fetch(`${this.cyberSecurityUnsubscribeUrl}${request.getOptInId()}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=UTF-8"
            },
            body: JSON.stringify({optInId: request.getOptInId()})
        });
        return promiseFromJsonResponse(response);
    }
}


export class MissionOneMidRequest {
    public constructor(private readonly mid: string) {
    }

    public getMid(): string {
        return this.mid;
    }
}

export class CyberSecurityOptInIDRequest {
    public constructor(private readonly optInId: string) {
    }

    public getOptInId(): string {
        return this.optInId;
    }
}