import {customElement, property, state} from "lit/decorators.js";
import {html, LitElement, type TemplateResult} from "lit";
import type {SubmitResponse, SubmitService} from "./formService";
import {resolve} from "../../container";
import {
    DATA_SUBSCRIBE_EVENT,
    type FormEvent,
    type FormEventData,
    type FormInitEvent,
    type FormStepInitEvent,
    type FormSubscriptionEvent,
    INPUT_ELEMENT_EVENT,
    INPUT_ELEMENT_INIT_EVENT,
    STEP_INIT_EVENT
} from "./formEvents";
import type {PrimitiveType, PropertyMap} from "../../common/utils/objects";
import type {FormSubscription} from "./subscriptions";
import {ScrollService} from "../../common/scroll";
import {SessionStorage} from "../../common/clientStorage";
import {LanguagesService} from "../../common/languages";
import {EopOverlaySpinner} from "../../page/elements/spinner";
import {GLOBAL} from "../../common/globals";
import {FormSessionId} from "./formSessionId";
import Styles from "./form.lit.scss";
import type {StepData} from "./formStep";
import {EOP_ERRORS, schedule} from "../../common/utils/promises";
import {isDefined, isUndefined} from "../../common/utils/basics";
import jexl from "jexl";
import {Tracking} from "../../tracking/old/tracking";

type Step = {
    id: string;
    label: string;
    nextButtonLabel: string;
    prevButtonLabel: string;
    validate: ((silent?: boolean) => boolean);
    isReachable: boolean;
};

const EMPTY_STEP: Step = {
    id: "",
    label: "",
    nextButtonLabel: "",
    prevButtonLabel: "",
    validate: () => false,
    isReachable: false
};

@customElement("eop-form")
export class EopForm extends LitElement {

    public static readonly styles = Styles;

    @property({attribute: "config-id"})
    private configId: string;
    @property({attribute: "submit-service-id"})
    private submitServiceId: string;
    @state()
    private resultStepId: string;
    @state()
    private currentStepIndex: number;
    @state()
    private steps: Step[];

    private data: PropertyMap;
    private subscriptions: Map<string, FormSubscription[]>;
    private spinner: EopOverlaySpinner;

    private submitService: SubmitService;

    public constructor(
        private scrollService: ScrollService = resolve(ScrollService),
        private sessionStorage: SessionStorage = resolve(SessionStorage),
        private formSessionId: FormSessionId = resolve(FormSessionId),
        private languages: LanguagesService = resolve(LanguagesService),
        private tracking: Tracking = resolve(Tracking)
    ) {
        super();
        this.data = {};
        this.subscriptions = new Map();
        this.spinner = new EopOverlaySpinner();
        this.resultStepId = "";
        this.steps = [];
        this.currentStepIndex = 0;

        this.addEventListener(INPUT_ELEMENT_INIT_EVENT, event => {
            const eventData = (event as FormInitEvent).detail;
            eventData.path.close(this.id);
            event.stopPropagation();
        });

        this.addEventListener(STEP_INIT_EVENT, event => {
            const eventData = (event as FormStepInitEvent).detail;
            this.addStep(this.mapStep(eventData.step));
            event.stopPropagation();
        });

        this.addEventListener(INPUT_ELEMENT_EVENT, event => {
            const eventData = (event as FormEvent<PrimitiveType>).detail;
            this.newData(eventData);
            event.stopPropagation();
        });

        this.addEventListener(DATA_SUBSCRIBE_EVENT, (event: Event) => {
            const eventData = (event as FormSubscriptionEvent).detail;
            const subscriptions = this.subscriptions.get(eventData.condition) ?? [];
            subscriptions.push(eventData.subscription);
            this.subscriptions.set(eventData.condition, subscriptions);
            event.stopPropagation();
        });
    }

    public connectedCallback(): void {
        super.connectedCallback();
        this.data.id = this.configId;
        this.data.sessionId = this.getSessionId();

        GLOBAL.bodyElement().append(this.spinner);

        this.submitService = resolve<SubmitService>(this.submitServiceId ? this.submitServiceId : "formService");
    }

    public disconnectedCallback(): void {
        this.data = {};
        this.subscriptions = new Map();
        this.resultStepId = "";
        this.steps = [];
        this.currentStepIndex = 0;
        this.spinner.remove();
        super.disconnectedCallback();
    }

    private addStep(step: Step): void {
        const steps = this.steps.clone();
        steps.push(step);
        this.steps = steps;
    }

    public render(): TemplateResult {
        return html`
            <form>
                ${this.renderWizard()}
                ${this.renderContent()}
            </form>`;
    }

    private renderContent(): TemplateResult {
        if (this.isInResultPhase()) {
            return html`
                <slot role="step" name=${this.resultStepId}></slot>`;
        } else {
            return html`
                <slot role="step" name=${this.getCurrentStep().id}></slot>
                <div class="eop-form-footer">
                    ${this.renderPrevButton()}
                    ${this.renderNextButton()}
                </div>
                <slot role="footer-text" name="footer-text"></slot>`;
        }
    }

    private renderPrevButton(): TemplateResult | null {
        if (!this.hasPrevStep()) {
            return null;
        }

        const trackingLabel = this.stepTrackingLabel("formStepBack", this.currentStepIndex - 1);
        return html`
            <div class="prev-button">
                <a class="prev" data-eventelement="link" data-tracking-label=${trackingLabel} @click=${this.prevStep}>
                    ${this.getCurrentStep().prevButtonLabel}
                </a>
            </div>`;
    }

    private renderNextButton(): TemplateResult {
        const trackingLabel = this.hasNextStep()
            ? this.stepTrackingLabel("formStepNext", this.currentStepIndex + 1)
            : this.trackingLabel("formSubmit");

        return html`
            <button type="button"
                    class="uni-button primary next"
                    data-tracking-label=${trackingLabel}
                    data-eventelement="button"
                    @click=${this.nextStep}
            >
                ${this.getCurrentStep().nextButtonLabel}
            </button>
        `;
    }

    private renderWizard(): TemplateResult | null {
        if (!(this.isMultiStep() && !this.isInResultPhase())) {
            return null;
        }
        return html`
            <div class="form-wizard">
                <a class="wizard-prev"
                   @click=${this.prevStep}
                   ?active=${this.hasPrevStep()}
                   data-tracking-label=${(this.stepTrackingLabel("formStepWizard", this.currentStepIndex - 1))}
                   data-eventelement="link">
                </a>
                <div class="wizard-steps">
                    ${(this.renderSteps())}
                </div>
                <a class="wizard-next"
                   @click=${this.nextStep}
                   ?active=${this.hasNextStep()}
                   data-tracking-label=${(this.stepTrackingLabel("formStepWizard", this.currentStepIndex + 1))}
                   data-eventelement="link">
                </a>
            </div>`;
    }

    private renderSteps(): TemplateResult[] {
        return this.steps.map(step => this.renderWizardStep(step));
    }

    private renderWizardStep(step: Step): TemplateResult {
        const handleStepClick = (): void => this.activateStep(step.id);
        return html`
            <a class="wizard-step"
               data-tracking-label=${(this.stepTrackingLabel("formStepWizard", this.steps.indexOf(step)))}
               data-eventelement="link"
               @click=${handleStepClick}
               ?active=${this.getCurrentStep().id === step.id}
               ?enabled=${step.isReachable}
            >
                <span class="wizard-text">${step.label}</span>
            </a>
        `;
    }

    private stepTrackingLabel(action: string, targetStepIndex: number): string {
        return this.trackingLabel(action, `${this.currentStepIndex + 1}->${targetStepIndex + 1}`);
    }

    private trackingLabel(action: string, details?: string): string {
        const detailsPart = details ? ("(" + details + ")") : "";
        return action + detailsPart + ":" + this.id;
    }

    private mapStep(stepData: StepData): Step {
        return {
            id: stepData.id,
            label: stepData.label,
            nextButtonLabel: stepData.nextButtonLabel,
            prevButtonLabel: stepData.prevButtonLabel,
            validate: stepData.validate,
            isReachable: this.steps.isEmpty()
        };
    }

    private getCurrentStep(): Step {
        return this.steps.at(this.currentStepIndex) ?? EMPTY_STEP;
    }

    private isInResultPhase(): boolean {
        return this.resultStepId !== "";
    }

    private nextStep(): void {
        const isValid = this.validateForm();
        if (!isValid) {
            return;
        }

        if (this.hasNextStep()) {
            this.currentStepIndex++;
            this.updateReachability();
        } else {
            this.submit().catch(EOP_ERRORS);
        }

        this.blur();
        this.scrollToForm();
    }

    private scrollToForm(): void {
        this.scrollService.scrollToElement(this, 50);
    }

    private hasNextStep(): boolean {
        return this.currentStepIndex < this.steps.length - 1;
    }

    private isMultiStep(): boolean {
        return this.steps.length > 1;
    }

    private hasPrevStep(): boolean {
        return this.currentStepIndex > 0;
    }

    private prevStep(): void {
        this.currentStepIndex--;
        this.scrollToForm();
    }

    private activateStep(stepId: string): void {
        const targetStep = this.steps.findFirst(step => step.id === stepId)!;
        this.currentStepIndex = this.steps.indexOf(targetStep);
    }

    private newData(data: FormEventData<PrimitiveType>): void {
        this.updateData(data.path.slice(1), data.inputValue);
        for (const [customLabel, customValue] of data.customValues) {
            this.updateData(this.customPathFrom(data.path.slice(1), customLabel), customValue);
        }
        for (const [condition, subscriptions] of this.subscriptions.entries()) {
            const result = this.evaluate(condition);
            if (isDefined(result)) {
                subscriptions.forEach(subscription => subscription(result));
            }
        }
        this.updateReachability();
    }

    private customPathFrom(path: string[], label: string): string[] {
        const labelPath = [...path];
        if (path.length > 0) {
            labelPath[labelPath.length - 1] = path.last() + "_" + label;
        }
        return labelPath;
    }

    private evaluate(condition: string): boolean | undefined {
        try {
            const result = jexl.evalSync(condition, this.data);
            return typeof result === "boolean" ? result : undefined;
        } catch (e) {
            console.error("invalid condition", e);
            return undefined;
        }
    }

    private updateData(path: string[], value: PrimitiveType): void {
        const last = path.pop()!;
        let currentData = this.data;
        for (const pathElement of path) {
            let nextElement = currentData[pathElement];
            if (isUndefined(nextElement)) {
                nextElement = {};
                currentData[pathElement] = nextElement;
            }
            currentData = nextElement;
        }
        currentData[last] = value;
    }

    private updateReachability(): void {
        const steps = this.steps.clone();
        for (const step of steps) {
            step.isReachable = this.isReachable(step.id);
        }
        this.steps = steps;
    }

    private async submit(): Promise<void> {
        const submit = this.submitService.submit({
            lang: this.languages.activeLanguageId(),
            formId: this.id,
            configId: this.configId,
            formData: this.data
        });

        await schedule(this.spinner.spinWhile(submit)
            .then(response => {
                this.handleSubmitResponse(response);
            })
            .catch(e => {
                this.handleErrorSubmitResponse("error");
                EOP_ERRORS(e);
            })).as("form-submit");
    }

    private handleSubmitResponse(response: SubmitResponse): void {
        if (response.success) {
            this.handleSuccessSubmitResponse();
        } else {
            this.handleErrorSubmitResponse(response.errorId ? response.errorId : "error");
        }
    }

    private handleSuccessSubmitResponse(): void {
        this.resultStepId = "success";
        this.sessionStorage.remove(this.id);
        this.formSessionId.clear(this.id);
        this.tracking.view({nameFragments: [this.id, "confirmation"]});
    }

    private handleErrorSubmitResponse(resultStepId: string): void {
        this.resultStepId = resultStepId;
        this.tracking.errorView({nameFragments: [this.id, "error"]});
    }

    private validateForm(): boolean {
        const validate = this.validationFor(this.getCurrentStep().id);
        return validate();
    }

    private isReachable(stepId: string): boolean {
        const validations = [];
        const targetIndex = this.steps.findIndex(step => step.id === stepId);
        if (targetIndex <= this.currentStepIndex) {
            return true;
        }
        for (const step of this.steps.slice(0, targetIndex)) {
            validations.push(step.validate);
        }
        return validations.every(validation => validation(true));
    }

    private validationFor(stepId?: string): ((silent?: boolean) => boolean) {
        return this.steps
                .findFirst(step => step.id === stepId)
                ?.validate
            ?? (() => false);
    }

    private getSessionId(): string {
        return this.formSessionId.get(this.id);
    }
}