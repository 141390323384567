import {Validation, type ValidationConfig} from "./validations";
import type {InputType} from "../commons";
import type {InputElement} from "../components/inputElement";

export type MaxLengthConfig = ValidationConfig<{
    id: string;
    maxLength: number;
}>;

export class MaxLength extends Validation {

    private constructor(messageText: string, onlyWarning: boolean, private maxLength: number) {
        super(messageText, onlyWarning);
    }

    public static from(config: MaxLengthConfig): MaxLength {
        return new MaxLength(config.message, config.onlyWarning, config.validation.maxLength);
    }

    protected valid(value: unknown): boolean {
        return typeof value === "string" && value.length <= this.maxLength;
    }

    public accept<T extends InputType>(inputElement: InputElement<T>, value?: T): void {
        if (!this.isValid(value)) {
            inputElement.addMessage(this.errorText, false);
        }
        if (this.isWarning(value)) {
            inputElement.addMessage(this.errorText, true);
        }
    }

}