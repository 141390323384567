import {type DefaultValidationConfig, Validation, type ValidationConfig} from "./validations";
import type {InputType} from "../commons";
import type {InputElement} from "../components/inputElement";

const POSTAL_CODE_PATTERN = new RegExp(/^\d{5}$/);

export class PostalCode extends Validation {

    private constructor(messageText: string, onlyWarning: boolean) {
        super(messageText, onlyWarning);
    }

    public static from(config: ValidationConfig<DefaultValidationConfig>): PostalCode {
        return new PostalCode(config.message, config.onlyWarning);
    }

    protected valid(value: unknown): boolean {
        return typeof value === "string"
            && (value === "" || POSTAL_CODE_PATTERN.test(value));
    }

    public accept<T extends InputType>(inputElement: InputElement<T>, value?: T): void {
        if (!this.isValid(value)) {
            inputElement.addMessage(this.errorText, false);
        }
        if (this.isWarning(value)) {
            inputElement.addMessage(this.errorText, true);
        }
    }

}