import {Directive, type DirectiveParameters, type PartInfo, PartType} from "lit/directive.js";
import type {PropertyMap} from "../utils/objects";
import type {ElementPart} from "lit";
import {noChange} from "lit";

export class SpreadAttributes extends Directive {

    public constructor(partInfo: PartInfo) {
        super(partInfo);
        if (partInfo === null) { // TODO remove debugInfo asap - #3222308
            throw Error("debug: partInfo is null (SpreadAttributes)");
        }
        if (partInfo.type !== PartType.ELEMENT) {
            console.error("The `spreadAttribute` directive must be used directly on the `element` tag");
        }
    }

    public update(part: ElementPart, [attributes]: DirectiveParameters<this>): symbol {
        for (const attributesKey in attributes) {
            if (part.element?.getAttribute(attributesKey) !== attributes[attributesKey]) {
                part.element?.setAttribute(attributesKey, attributes[attributesKey] ?? "");
            }
        }
        return this.render(attributes);
    }

    public render(_attributes: PropertyMap<string>): symbol {
        return noChange;
    }
}