import {Validation, type ValidationConfig} from "./validations";
import type {InputType} from "../commons";
import type {InputElement} from "../components/inputElement";

export type PermittedValuesConfig = ValidationConfig<{
    id: string;
    permittedValues: string;
}>;

export class PermittedValues extends Validation {

    private values: string[];

    private constructor(messageText: string, onlyWarning: boolean, permittedValues: string) {
        super(messageText, onlyWarning);
        this.values = permittedValues.split(",")
            .map(value => value.trim());
    }

    public static from(config: PermittedValuesConfig): PermittedValues {
        return new PermittedValues(config.message, config.onlyWarning, config.validation.permittedValues);
    }

    protected valid(value: unknown): boolean {
        return typeof value === "string"
            && (value === "" || this.values.includes(value));
    }

    public accept<T extends InputType>(inputElement: InputElement<T>, value?: T): void {
        if (!this.isValid(value)) {
            inputElement.addMessage(this.errorText, false);
        }
        if (this.isWarning(value)) {
            inputElement.addMessage(this.errorText, true);
        }
    }

}