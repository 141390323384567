import {Validation} from "./validations";
import type {InputType} from "../commons";
import type {InputElement} from "../components/inputElement";
import {noop} from "../../../common/utils/functions";

export class Optional extends Validation {

    public constructor() {
        super("", false);
    }

    protected valid(value: unknown): boolean {
        return true;
    }

    public accept<T extends InputType>(inputElement: InputElement<T>, value?: T): void {
        noop();
    }
}