import {type DefaultValidationConfig, InputElementType, Validation, type ValidationConfig} from "./validations";
import type {InputType} from "../commons";
import type {InputElement} from "../components/inputElement";

const PHONE_NUMBER_PATTERN = new RegExp(/^[0-9\s./\-+]*$/);

export class PhoneNumber extends Validation {

    private constructor(messageText: string, onlyWarning: boolean) {
        super(messageText, onlyWarning);
    }

    public static from(config: ValidationConfig<DefaultValidationConfig>): PhoneNumber {
        return new PhoneNumber(config.message, config.onlyWarning);
    }

    protected valid(value: unknown): boolean {
        return typeof value === "string" && PHONE_NUMBER_PATTERN.test(value);
    }

    public accept<T extends InputType>(inputElement: InputElement<T>, value?: T): void {
        inputElement.setType(InputElementType.PHONE);
        if (!this.isValid(value)) {
            inputElement.addMessage(this.errorText, false);
        }
        if (this.isWarning(value)) {
            inputElement.addMessage(this.errorText, true);
        }
    }
}